import React, { useEffect, useMemo, useState } from "react";
import styles from "./PackageCard.module.scss";
import { Group16356, StarIcon, Timesvgrepo } from "@common/icons";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import dayjs from "dayjs";
import useWidth from "@helpers/hooks/useWidth";
import "dayjs/locale/ar"; // without this line it didn't work
import { HeartFilled, EllipsisOutlined, CopyOutlined, EyeOutlined, BarChartOutlined, FormOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, message, Space, Switch } from "antd";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store/configureStore";
import { setError } from "store/reducers/validationPopup";
import parse from "html-react-parser";
import Api from "@config/network/ApiConfig";
import { setUmrahJourneyInfo } from "store/reducers/umrahJourney";
import { useIsB2c } from "@helpers/hooks/useIsB2c";
import hotelPlacholder from "../../../public/assets/common/images/Hotel.png";
import { setCompanyProfile, setCurrentUser, setSelectedB2BRooms } from "store/reducers/user";
import RefundTag from "@components/RefundTag/RefundTag";
import { MdOutlineCalendarToday, MdOutlinePlace, MdTimelapse } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import queryClient from "@config/react-query/queryClient";
import { queryKeys } from "@config/react-query/queryKeys";

interface PackageCardProps {
	data?: any;
	isFavouriteCard?: any;
	handelPackgeFavProps?: any;
	numberOfSeats?: any;
	isAdmin?: any;
	setDeleteVisibleDialog?: any;
	editRoute?: any;
	setWarnVisibleDialog?: any;
	clonePackageFunction?: any;
	handlePackageSelect?: any;
	isHotel?: any;
	user?: any;
	trackToken?: any;
	numberOfNights?: any;
	isThingsToDo?: any;
	link?: any;
	showFav?: any;
}

const PackageCard: React.FC<PackageCardProps> = ({
	data,
	isFavouriteCard,
	handelPackgeFavProps,
	numberOfSeats,
	isAdmin,
	setDeleteVisibleDialog,
	editRoute,
	setWarnVisibleDialog,
	clonePackageFunction,
	handlePackageSelect,
	isHotel,
	user,
	trackToken,
	numberOfNights,
	isThingsToDo,
	showFav = false,
	link
}) => {
	const router = useRouter();
	const { t, i18n } = useTranslation("website");
	const isArabicLang = i18n.language === "ar";
	const { width } = useWidth();
	const localCurrency = localStorage?.getItem("UserCurrency") && localStorage?.getItem("UserCurrency") !== "undefined" ? JSON?.parse(localStorage?.getItem("UserCurrency") || "{}") : null;
	const currencyName = localCurrency?.abbreviation;
	const { user: selectorUser }: any = useSelector((state) => state);
	if (isArabicLang) dayjs.locale("ar");
	else dayjs.locale("en");
	const isCompanyApproved = selectorUser?.currentUser?.isCompanyApproved;
	const userRoles = selectorUser?.currentUser?.roles;
	const [itemPublished, setItemPublished] = useState(data?.published);
	const dispatch = useAppDispatch();
	const [publishLoading, setpublishLoading] = useState(false);
	const userCanPublish = userRoles?.some((role: any) => role === "PublishPackages");
	// console.log(itemPublished, data?.packageName, "itemPublished");
	const onB2cHotelClick = () => {
		dispatch(setSelectedB2BRooms(null));
		dispatch(
			setUmrahJourneyInfo({
				selectedMakkahRooms: { items: null, name: "" },
				selectedMadinaRooms: { items: null, name: "" }
			})
		);
		router.push(
			{
				pathname: router?.pathname,
				query: {
					...router?.query,
					code: data?.code,
					name: data?.name,
					countryCode: "SA",
					vendor: data?.vendor,
					provider: data?.provider,
					trackToken: trackToken,
					status: data?.status,
					step: isB2c ? router.query?.step : 1,
					...(isB2c ? { subStep: 1 } : {})
				}
			},
			undefined,
			{ locale: isArabicLang ? "ar" : "en" }
		);
		if (!isB2c) return;
		dispatch(
			setUmrahJourneyInfo({
				roomQuery: {
					code: data?.code,
					name: data?.name,
					countryCode: "SA",
					vendor: data?.vendor,
					provider: data?.provider,
					trackToken: trackToken,
					status: data?.status,
					step: 1,
					...router?.query
				}
			})
		);
	};
	const isB2c = useIsB2c();
	const handleItemPublished = (item: any) => {
		handlePackageSelect(data);
		if (isCompanyApproved) {
			setpublishLoading(true);
			if (!itemPublished) {
				Api.put("PackagesAPI", `/Package/${data?.internalID}/Publish`, data?.internalID)
					.then((res) => {
						if (res?.responseCode === 104) {
							setWarnVisibleDialog(data?.internalID);
							return false;
						}
						message.success(res?.responseMessage);
						setItemPublished(true), setpublishLoading(false);
					})
					.catch(() => {
						setpublishLoading(false);
						setWarnVisibleDialog(data?.internalID);
					})
					.finally(() => {
						setpublishLoading(false);
					});
			} else {
				Api.put("PackagesAPI", `/Package/${data?.internalID}/UnPublish`, data?.internalID)
					.then((res) => {
						setItemPublished(false);
					})
					.catch(() => {
						// setItemPublished(false);
						setpublishLoading(false);
					})
					.finally(() => {
						setpublishLoading(false);
					});
			}
		} else {
			getCompanyInfo();
		}
	};

	const getCompanyInfo = async () => {
		setpublishLoading(true);
		let res = await Api.get("AuthenticationAPI", "/Company/GetCompanyProfileInfo");
		if (res?.responseData?.isApproved) {
			dispatch(setCurrentUser({ ...selectorUser?.currentUser, isCompanyApproved: res?.responseData?.isApproved }));
			Api.put("PackagesAPI", `/Package/${data?.internalID}/Publish`, data?.internalID)
				.then((res) => {
					if (res?.responseCode === 104) {
						setWarnVisibleDialog(data?.internalID);
						return false;
					}
					message.success(res?.responseMessage);
					setItemPublished(true), setpublishLoading(false);
				})
				.catch(() => {
					setpublishLoading(false);
					setWarnVisibleDialog(data?.internalID);
				})
				.finally(() => {
					setpublishLoading(false);
				});
		} else {
			setpublishLoading(false);
			dispatch(
				setError({
					title: t("common.buttons.Action not Allowed"),
					body: t("common.buttons.you can't take an action to packages, until you verify your account"),
					firstAction: t("common.buttons.Continue")
				})
			);
		}
		dispatch(setCompanyProfile(res?.responseData));
	};

	const handelPackgeFav = (id: string) => {
		Api.put("PackagesAPI", `/Package/${id}/SetAsUserFavorite`, id).then(
			(res) => {
				queryClient.invalidateQueries([isFavouriteCard ? queryKeys.favourite.getPackageFavouriteList : queryKeys.package.getPackageB2cList]);
			},
			(err) => {
				console.log(err);
			}
		);
	};

	const handelActivityFav = (id: string) => {
		Api.put("PackagesAPI", `/ThingsToDo/${id}/SetThingsToDoAsUserFavorite`, id).then(
			(res) => {
				if (res?.responseCode === 200) {
					queryClient.invalidateQueries([isFavouriteCard ? queryKeys.favourite.getThingsToDoFavouriteList : queryKeys.thingsToDo.getThingsToDoB2cList]);
				}
			},
			(err) => {
				console.log(err);
			}
		);
	};

	const menu = (
		<Menu
			items={[
				data?.packageTypes && {
					key: "0",
					label: <p>{t("profile.createPackage.Sales Summary")}</p>,
					onClick: () => router.push(`/profile/package/statistics/${data?.internalID}`),
					icon: <BarChartOutlined />
				},
				data?.packageTypes && {
					type: "divider"
				},
				{
					key: "1",
					label: <p>{t("profile.createPackage.Clonepackage")}</p>,
					onClick: () => clonePackageFunction(data?.internalID),
					icon: <CopyOutlined />
				},
				{
					type: "divider"
				},
				{
					key: "2",
					label: <p>{t("profile.createPackage.Edit")}</p>,
					onClick: () => editRoute(data?.internalID),
					disabled: (!userCanPublish && itemPublished) || data?.isExpired,
					icon: <FormOutlined />
				},
				{
					type: "divider"
				},
				{
					key: "3",
					danger: true,
					onClick: setDeleteVisibleDialog,
					disabled: (!userCanPublish && itemPublished) || data?.isExpired,
					icon: <DeleteOutlined />,
					label: t("profile.createPackage.Delete")
				}
			]}
			onClick={() => handlePackageSelect(data)}
		/>
	);

	useEffect(() => {
		if (itemPublished !== data?.published) {
			setItemPublished(data?.published);
		}
	}, [data]);

	return (
		<div
			className={styles["package-card-wrapper"]}
			onClick={() => {
				if (width < 768) {
					if (isAdmin) {
						return false;
					}
					if (isHotel) {
						onB2cHotelClick();
						return;
					}
					if (isThingsToDo) {
						router.push(`/things-to-do/${data?.internalID}`, undefined, {
							locale: isArabicLang ? "ar" : "en"
						});
					} else {
						router.push(`/p/${data?.internalID}`, undefined, {
							locale: isArabicLang ? "ar" : "en"
						});
					}
				}
			}}
		>
			<div className={styles["package-card--image"]}>
				{data?.photoCoverID || data?.bannerImagePath ? (
					<Image
						onClick={() => {
							if (isHotel) {
								onB2cHotelClick();
								return false;
							}
							if (isHotel) return;
							if (isAdmin) {
								// router.push(`/profile/package/statistics/${data?.internalID}`, `/profile/package/statistics/${data?.internalID}`, {
								//     locale: isArabicLang ? "ar" : "en"
								// });
								return false;
							}
							if (isThingsToDo) {
								router.push(`/things-to-do/${data?.internalID}`, `/things-to-do/${data?.internalID}`, {
									locale: isArabicLang ? "ar" : "en"
								});
							} else {
								router.push(`/p/${data?.internalID}`, `/p/${data?.internalID}`, {
									locale: isArabicLang ? "ar" : "en"
								});
							}
						}}
						style={{ borderRadius: 8 }}
						src={isHotel ? "https:" + data?.bannerImagePath : `${process.env.FILES_API}/AccessFile/${data?.photoCoverID}` || ""}
						fill
						alt={data?.name || "images"}
					/>
				) : (
					<span
						onClick={() => {
							if (isHotel) {
								onB2cHotelClick();
								return false;
							}
							// if (isHotel) return;
							if (isAdmin) {
								return false;
							}
							if (isThingsToDo) {
								router.push(`/things-to-do/${data?.internalID}`, `/things-to-do/${data?.internalID}`, {
									locale: isArabicLang ? "ar" : "en"
								});
							} else {
								router.push(`/p/${data?.internalID}`, `/p/${data?.internalID}`, {
									locale: isArabicLang ? "ar" : "en"
								});
							}
						}}
						className={styles["placeholder-image"]}
					>
						{isHotel ? <Image style={{ borderRadius: 8 }} src={hotelPlacholder} fill alt={data?.name || "images"} /> : <Group16356 />}
					</span>
				)}
				{showFav && (
					<div
						className={data?.isFavorite ? styles[`package-fav__done`] : styles[`package-fav`]}
						onClick={() => (isThingsToDo ? handelActivityFav(data?.internalID) : handelPackgeFav(data?.internalID))}
					>
						{data?.isFavorite ? <HeartFilled /> : <HeartFilled />}
					</div>
				)}
			</div>
			{isHotel && (
				<>
					<div className={styles["package-card--content"]}>
						<h3 className={styles["package-content__title"]}>
							<p className={styles["package-content__title_holder"]}>{data?.name || "-"} </p>
						</h3>
						{data?.locationInformation && (
							<div className={styles["package-content__location"]}>
								<div className={styles["package-places"]}>
									<MdOutlinePlace style={{ color: "#a7a7a7" }} />

									<p>{data?.locationInformation}</p>
								</div>
								<div className={styles["package--types"]}>
									<p>{data?.packageTypes && data?.packageTypes?.join(" , ")}</p>
								</div>
							</div>
						)}
						<p className={styles["package-content__desc"]}>{parse("" + parse((data?.description as string) || "") || "")}</p>
					</div>

					<div className={styles["package-card--summry"]} style={{ justifyContent: "space-between" }}>
						<Space>
							<div className={styles["package-card--stars"]}>
								<StarIcon /> {data?.rating}
							</div>
							{/* <RefundTag isRefundable /> */}
						</Space>
						<div className={styles["package-card--price"]}>
							<span className="price-content-holder">
								{" "}
								<div className={styles["price-lable"]}>{t("profile.createPackage.fields.common.placeholder.start from")}</div>
								<span>{data?.currencyCode}</span> {data?.startFromPricePerNight}
							</span>
							<p>{t("common.sentence.perNightDynamic", { night: "" })}</p>
						</div>
						<Button type="primary" onClick={onB2cHotelClick}>
							{t("profile.package.Select Rooms")}
						</Button>
					</div>
				</>
			)}
			{!isHotel && (
				<>
					<div className={styles["package-card--content"]}>
						<h3 className={styles["package-content__title"]}>
							{isAdmin ? (
								<>
									<p className={styles["package-content__title_holder"]}>
										{data?.packageName || "-"}{" "}
										{data?.packageNumber && (
											<span className={styles["package-num-holder"]}>
												{" "}
												{"  "}ID: {data?.packageNumber}
											</span>
										)}
									</p>
								</>
							) : (
								<Link href={isThingsToDo ? `/things-to-do/${data?.internalID}` : `/p/${data?.internalID}`} className={styles["package-content__title_holder"]}>
									{data?.packageName || "-"}
								</Link>
							)}
						</h3>
						<div className={styles["package-content__location"]}>
							<div className={styles["package-places"]}>
								<MdOutlinePlace style={{ color: "#a7a7a7" }} />
								<p>
									{data?.cityName}, {data?.countryName}
								</p>
							</div>
							<div className={styles["package--types"]}>
								<p>{data?.packageTypes && data?.packageTypes?.join(" , ")}</p>
							</div>
						</div>
						<div className={styles["package-content__date"]}>
							{data?.fixed ? (
								<div className={styles["package-data-availability"]}>
									<MdOutlineCalendarToday style={{ fontSize: 20, color: "#949E89" }} />
									{dayjs(data?.startDate).format("D MMM")} {" - "}
									{dayjs(data?.endDate).format("D MMM")}
								</div>
							) : (
								<div className={styles["package-data-availability--flexable"]}>
									<MdOutlineCalendarToday style={{ fontSize: 20, color: "#949E89" }} />
									<div className={styles["location__availability"]}>
										{t("profile.package.Availability")}
										{"   "}
										{dayjs(data?.startDate).format("D MMM")} {" - "}
										{dayjs(data?.endDate).format("D MMM")}
									</div>
									<div className={styles["location__everday"] + " accent-bg"}>{t("profile.package.Everyday")}</div>
								</div>
							)}
						</div>
						<p className={styles["package-content__desc"]}>{parse(data?.description || "")}</p>
					</div>

					<div className={styles["package-card--summry"]}>
						{isAdmin && (
							<div className={styles["package-card--seats"]}>
								{isAdmin && (
									<Dropdown overlay={menu} trigger={["click"]} overlayClassName={styles["package-card--dropdown"]}>
										<EllipsisOutlined className={styles["package-card--menu"]} />
									</Dropdown>
								)}
							</div>
						)}
						<div className={styles["package-card--duration"]}>
							<MdTimelapse style={{ fontSize: 24, color: "#949E89" }} /> {data?.duration} {t("profile.package.Days")}, {data?.duration - 1} {t("profile.package.Nights")}
						</div>
						<div className={styles["package-card--price"]}>
							<span className="price-content-holder">{data?.price}</span>
							<p>
								{data?.currencyAbbreviation || currencyName} / {t("profile.package.Person")}
							</p>
						</div>
						{(() => {
							if (isFavouriteCard) {
								return (
									<div className={styles["package-card--favourite"]}>
										<Button
											block
											size="large"
											type="text"
											className={styles["btn-favourite-remove"]}
											onClick={(e) => {
												e.stopPropagation();
												isThingsToDo ? handelActivityFav(data?.internalID) : handelPackgeFav(data?.internalID);
											}}
										>
											{t("common.buttons.remove")}
											<AiOutlineClose size={20} />
										</Button>
										<Button
											block
											type="primary"
											onClick={() => {
												router.push(
													{
														pathname: isThingsToDo ? `/things-to-do/${data?.internalID}` : `/p/${data?.internalID}`
													},
													undefined,
													{ locale: isArabicLang ? "ar" : "en" }
												);
											}}
										>
											{t("profile.package.Select")}
										</Button>
									</div>
								);
							} else if (isAdmin) {
								return data?.isExpired ? (
									<div className={styles["package-card--expired"]}>{t("profile.personal.Expired")}</div>
								) : (
									<div className={styles["switch-holder"] + " switch-holder"}>
										<Switch checked={itemPublished} loading={publishLoading} onChange={handleItemPublished} disabled={!userCanPublish} />
										<span className={`${styles["switch-text"]} ${itemPublished ? styles["text-green"] : styles["text-red"]}`}>
											{itemPublished ? t("profile.personal.Published") : t("profile.personal.Unpublished")}
										</span>
									</div>
								);
							} else {
								return (
									<>
										<Button
											type="primary"
											onClick={() => {
												if (isThingsToDo) {
													router.push(
														{
															pathname: `/things-to-do/${data?.internalID}`,
															query: { numberOfSeats }
														},
														undefined,
														{ locale: isArabicLang ? "ar" : "en" }
													);
												} else {
													router.push(
														{
															pathname: `/p/${data?.internalID}`,
															query: { numberOfSeats }
														},
														undefined,
														{ locale: isArabicLang ? "ar" : "en" }
													);
												}
											}}
										>
											{t("profile.package.Select")}
										</Button>
									</>
								);
							}
						})()}
					</div>
				</>
			)}
		</div>
	);
};
export default PackageCard;
