import { useEffect, useState } from "react";

export const useScroll = () => {
	const [scrollDir, setScrollDir] = useState<"scrolling down" | "scrolling up" | "">("");
	const [scrolling, setScrolling] = useState<boolean>(false);
	const [scrollY, setScrollY] = useState<number>(0);
	useEffect(() => {
		const threshold = 0;
		let lastScrollY = window.pageYOffset;
		let ticking = false;

		const updateScrollDir = () => {
			const scrollY = window.pageYOffset;

			if (Math.abs(scrollY - lastScrollY) < threshold) {
				ticking = false;
				return;
			}
			setScrollDir(scrollY > lastScrollY ? "scrolling down" : "scrolling up");
			lastScrollY = scrollY > 0 ? scrollY : 0;
			ticking = false;
		};

		const onScroll = () => {
			let elem: any = document.querySelector(".SearchContainer");
			let rect = elem?.getBoundingClientRect();
			if (rect?.top === 0) {
				setScrolling(false);
			} else {
				// setTimeout(() => {
				// 	let elem: any = document.querySelector(".packages-wrapper");
				// 	let rect = elem.getBoundingClientRect();
				// 	if (rect.top === 0) {
				// 		setScrolling(false);
				// 	} else {
				// 		setScrolling(true);
				// 	}
				// }, 1000);
				setScrolling(true);
			}
			setScrollY(window.pageYOffset);
			// if (window.pageYOffset === 0) {
			// 	setScrolling(false);
			// } else {
			// 	setScrolling(true);
			// }
			if (!ticking) {
				window.requestAnimationFrame(updateScrollDir);
				ticking = true;
			}
		};

		window.addEventListener("scroll", onScroll);
		return () => window.removeEventListener("scroll", onScroll);
	}, [scrollDir]);

	return { scrollDir, scrolling, scrollY };
};
