import Image from "next/image";
import React from "react";

export default function ImageCustom({ src, className, alt, style, onClick }: any) {
	return (
		<>
			<Image
				/* quality="100%" */
				// priority={true}
				className={className}
				loading="lazy"
				onClick={onClick}
				// style={{ ...style }}
				sizes="(min-width: 75em) 33vw, (min-width: 48em) 50vw, 100vw"
				fill
				src={src}
				alt={alt || "image"}
				// placeholder="blur"
			/>
		</>
	);
}
