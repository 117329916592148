import { BedIcon, GreyCloseBtnIcon, PaxInfoAdultsIcon, PaxInfoB2cDeleteIcon, PaxInfoChildIcon, PurplePlusBtnIcon } from "@common/icons";
import { Button, Col, Form, FormInstance, InputNumber, Modal, Row, Select, Space, message } from "antd";
import { PlusCircleFilled, MinusCircleFilled, PlusCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "next-i18next";
import { PaxInfoModalStyle } from "./PaxInfoModalStyle";
import useCalcAllRooms from "@helpers/hooks/useCalAllRooms";
import { useIsEqualRoomGroups } from "@helpers/hooks/useIsEqualRoomGroups";

const FIELD_NAME = "roomAndTravelers";
interface PaxInfoModalProps {
	visible: boolean;
	onFormlistModalSave: () => void;
	form: FormInstance;
	name?: string;
	isB2c?: boolean;
	onCancel?: () => void;
	limit?: number;
}

const childAgesOptions = [...Array(10).keys()].map((el) => ({ value: el + 1, label: el + 1 }));
const PaxInfoModal: React.FC<PaxInfoModalProps> = ({ visible, form, onFormlistModalSave, name, isB2c, onCancel, limit }) => {
	const { t } = useTranslation("website");
	const [currentValue, setCurrentValue] = useState({});

	useEffect(() => {
		setCurrentValue(form.getFieldValue(name ?? FIELD_NAME));
	}, [visible]);

	const roomWatcher = Form.useWatch([name ?? FIELD_NAME], form) || [];
	const allRoomCalc = useCalcAllRooms(roomWatcher);

	const isOverLimit = useMemo(() => {
		if (!limit) return false;
		return allRoomCalc?.ADT + allRoomCalc?.CHD >= limit;
	}, [allRoomCalc]);

	const isEqualRoomGroups = useIsEqualRoomGroups(roomWatcher);
	return (
		<PaxInfoModalStyle centered closable={false} maskClosable={false} className="RoomAndTravelModal" footer={null} open={visible}>
			<Form.List name={name ?? FIELD_NAME}>
				{(fields, { add, remove }) => (
					<>
						<div className="RoomAndTravelModal__Fields">
							{fields?.map(({ name, key, ...restField }, i) => {
								return (
									<Form.Item noStyle {...restField} key={key}>
										{!isB2c && i === 0 && (
											<h3 className="RoomAndTravelModal__RoomGroupTitle">
												{t("sections.umrah.roomGroup")} {i + 1}
											</h3>
										)}
										<Row className={`RoomAndTravelModal__Field ${isB2c ? "RemoveBorderBottom" : ""}`}>
											{i > 0 && !isB2c && (
												<Col span={24}>
													<div className={"RoomAndTravelModal__RemoveBtn"}>
														{/* <p className={classes.RoomAndTravelModal__RemoveBtn__Label}>{t("common.buttons.addRoom")}</p> */}
														<h3 className="RoomAndTravelModal__RoomGroupTitle">
															{t("sections.umrah.roomGroup")} {i + 1}
														</h3>
														<p className="RoomAndTravelModal__RemoveBtn__Label"></p>
														<Space style={{ cursor: "pointer" }} onClick={() => remove(name)}>
															<p>{t("common.buttons.remove")}</p>
															<GreyCloseBtnIcon />
														</Space>
													</div>
												</Col>
											)}
											<Col span={isB2c ? 7 : 11}>
												<Space>
													<BedIcon />
													<p>{isB2c ? t("forms.fields.labels.roomNo") : t("forms.fields.labels.roomCount")}</p>
												</Space>
											</Col>
											<Col style={{ display: "flex", gap: 6 }} span={6}>
												<PaxInfoAdultsIcon />
												{t("forms.fields.labels.adults")}
											</Col>
											<Col style={{ display: "flex", gap: 6 }} span={6} offset={isB2c ? 3 : 1}>
												<PaxInfoChildIcon />
												{t("forms.fields.labels.childs")}
											</Col>
											{isB2c ? (
												<Col span={6}>
													<div className="RoomAndTravelModal__B2cRoom">
														<p>
															{t("common.sentence.room")} {i + 1}
														</p>
														{i > 0 && (
															<div onClick={() => remove(name)}>
																<PaxInfoB2cDeleteIcon />
															</div>
														)}
													</div>
												</Col>
											) : (
												<Col span={10}>
													<Form.Item name={[name, "room_quantity"]} className="RoomAndTravelModal__Input input-holder">
														<InputNumber
															controls={{
																upIcon: <PlusCircleFilled className="RoomAndTravelModal__Input__Icon" />,
																downIcon: <MinusCircleFilled className="RoomAndTravelModal__Input__Icon" />
															}}
															min={1}
														/>
													</Form.Item>
												</Col>
											)}
											{/* <Col span={1} /> */}
											<Col span={isB2c ? 8 : 6} offset={1}>
												<Form.Item name={[name, "ADT"]} className="RoomAndTravelModal__Input RoomAndTravelModal__InputWithLabel input-holder">
													<InputNumber
														controls={{
															upIcon: (
																<Button
																	ghost
																	onClick={() => {
																		if (isOverLimit) {
																			message.error(t("forms.validations.maxNumberPerB2cRequest", { limit: 35 }));
																			return;
																		}
																	}}
																	className="RoomAndTravelModal__Input__Icon"
																>
																	<PlusCircleFilled />
																</Button>
															),
															downIcon: <MinusCircleFilled className="RoomAndTravelModal__Input__Icon" />
														}}
														min={1}
														max={limit ? Math.min(limit - (allRoomCalc?.ADT + allRoomCalc?.CHD) + form.getFieldValue([FIELD_NAME, name, "ADT"]), 6) : 6}
														// disabled={isOverLimit}
													/>
												</Form.Item>
												{/* <p className='RoomAndTravelModal__Input__Suffix'>{t("forms.fields.labels.adults")}</p> */}
											</Col>
											<Col span={isB2c ? 8 : 6} offset={1}>
												<Form.Item name={[name, "CHD"]} className="RoomAndTravelModal__Input RoomAndTravelModal__InputWithLabel  input-holder">
													<InputNumber
														controls={{
															upIcon: (
																<Button
																	ghost
																	onClick={() => {
																		if (isOverLimit) {
																			message.error("Max pax is 35");
																			return;
																		}
																	}}
																	// disabled={isOverLimit}
																	className="RoomAndTravelModal__Input__Icon"
																>
																	<PlusCircleFilled />
																</Button>
															),
															downIcon: (
																<MinusCircleFilled
																	className="RoomAndTravelModal__Input__Icon"
																	onClick={() => {
																		const agesValue = form.getFieldValue([FIELD_NAME, name, "age"]) as number[];
																		agesValue?.pop();
																		form.setFieldValue([FIELD_NAME, name, "age"], agesValue);
																	}}
																/>
															)
														}}
														min={0}
														max={limit ? Math.min(limit - (allRoomCalc?.ADT + allRoomCalc?.CHD) + form.getFieldValue([FIELD_NAME, name, "CHD"]), 4) : 4}
														// disabled={isOverLimit}
													/>
												</Form.Item>
												{/* <p className='RoomAndTravelModal__Input__Suffix'>{t("forms.fields.labels.childs")}</p> */}
											</Col>
											{!isB2c && (
												<div className="RoomAndTravelModal__TotalInfo">
													<Space align="center" className="RoomAndTravelModal__TotalInfo">
														<p className="RoomAndTravelModal__TotalInfo__Label">{t("common.sentence.total")}:</p>
														<p className="RoomAndTravelModal__TotalInfo__Text">{`${form.getFieldValue([FIELD_NAME, name, "room_quantity"]) || 0} ${t("forms.fields.labels.rooms")}`}</p>
														<p className="RoomAndTravelModal__TotalInfo__Text">{`${(form.getFieldValue([FIELD_NAME, name, "ADT"]) || 0) * form.getFieldValue([FIELD_NAME, name, "room_quantity"])} ${t(
															"forms.fields.labels.adults"
														)}`}</p>
														<p className="RoomAndTravelModal__TotalInfo__Text">{`${(form.getFieldValue([FIELD_NAME, name, "CHD"]) || 0) * form.getFieldValue([FIELD_NAME, name, "room_quantity"])} ${t(
															"forms.fields.labels.childs"
														)}`}</p>
													</Space>
												</div>
											)}
											{form.getFieldValue([FIELD_NAME, name, "room_quantity"]) > 1 && form.getFieldValue([FIELD_NAME, name, "CHD"]) > 0 && (
												<div className="RoomAndTravelModal__ChildWarning">
													<h5 className="RoomAndTravelModal__ChildWarning__Title">{t("sections.umrah.childAges")}</h5>
													<p className="RoomAndTravelModal__ChildWarning__Warning">{t("sections.umrah.childAgesWarning")}</p>
												</div>
											)}
											<Col span={24}>
												<Row gutter={[12, 0]} className="RoomAndTravelModal__Field__Childs">
													{Array.from({ length: form.getFieldValue([FIELD_NAME, name, "CHD"]) }).map((x, index) => (
														<>
															<Col span={6}>
																<Form.Item name={[name, "age", index]} className="input-holder">
																	<Select defaultValue={1} options={childAgesOptions}></Select>
																</Form.Item>
																<p className="RoomAndTravelModal__Input__Suffix">{t("forms.fields.labels.ages")}</p>
															</Col>
														</>
													))}
												</Row>
											</Col>
										</Row>
									</Form.Item>
								);
							})}
						</div>
						<div className="RoomAndTravelModal__Actions">
							<div
								className={`RoomAndTravelModal__Actions__AddBtn ${isOverLimit ? "RoomAndTravelModal__Actions__AddBtn--disabled" : ""}`}
								onClick={() => {
									if (isOverLimit) return;
									add({ room_quantity: 1, ADT: 1, CHD: 0 });
								}}
							>
								<PlusCircleOutlined />

								<p>{isB2c ? t("common.buttons.addRoom") : t("common.buttons.addRoomsGroup")}</p>
							</div>
							<Space>
								<Button
									ghost
									onClick={() => {
										form.setFieldValue(name ?? FIELD_NAME, currentValue);
										onCancel && onCancel();
									}}
									className="RoomAndTravelModal__Actions__SaveBtn"
									type="primary"
								>
									{t("common.buttons.cancel")}
								</Button>
								<Button
									onClick={() => {
										if (isEqualRoomGroups) {
											message.error(` ${t("common.buttons.Count of guests can't be the same in room")} ${isEqualRoomGroups.join(`${t("partner.and")} `)} `);
											return;
										}
										onFormlistModalSave();
									}}
									className="RoomAndTravelModal__Actions__SaveBtn"
									type="primary"
								>
									{t("common.buttons.save")}
								</Button>
							</Space>
						</div>
					</>
				)}
			</Form.List>
		</PaxInfoModalStyle>
	);
};

export default PaxInfoModal;
