import { useGetCurrentCountry } from '@queries/masterdata/useMasterdata';
import { useEffect, useState } from 'react';

export const useFetchSingleCountryHook = ({
	destinations,
	countryId,
	name,
	onSuccess,
	keyNameForDestinations
}: {
	destinations: any;
	countryId: string | string[] | undefined;
	name: string;
	onSuccess?: (res: any) => void;
	keyNameForDestinations?: string;
}) => {
	const [fetchCurrentCountry, setFetchCurrentCountry] = useState<boolean>(false);

	useEffect(() => {
		if (destinations?.pages?.length === 1 && destinations?.pages[0]?.responseData?.length > 0 && !fetchCurrentCountry) {
			const findCurrentCountry = destinations?.pages[0]?.responseData?.some((res: any) => {
				return res?.[keyNameForDestinations || 'countryId'] === countryId;
			});
			setFetchCurrentCountry(!findCurrentCountry);
		}
	}, [destinations]);

	return useGetCurrentCountry({ countryId, name, enabled: fetchCurrentCountry, onSuccess });
};
