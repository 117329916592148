import React from "react";
import { Drawer as AntDrawer, DrawerProps as AntDrawerProps } from "antd";
import classes from "./Drawer.module.scss";

interface DrawerProps {
    visible: boolean;
    rootClassName?: string;
    children: React.ReactNode;
}

const Drawer: React.FC<DrawerProps & AntDrawerProps> = ({ visible, rootClassName, children, ...props }) => {
    return (
        <AntDrawer headerStyle={{ display: "none" }} open={visible} placement="bottom" rootClassName={[classes.Drawer, rootClassName].join(" ")} {...props}>
            {children}
        </AntDrawer>
    );
};

export default Drawer;
