import React, { useMemo } from "react";

interface roomValue {
	room_quantity: number;
	ADT: number;
	CHD: number;
}

type roomValues = roomValue[];

const useCalcAllRooms = (roomsValues: roomValues | undefined) => {
	const allRoomAndTraversFieldsValue: roomValue = useMemo(() => {
		return roomsValues?.reduce((el: any, currentEl: any) => {
			// console.log(currentEl, "currentEl");
			el["room_quantity"] = (el?.room_quantity || 0) + (+currentEl?.room_quantity || 0);
			el["ADT"] = (currentEl?.ADT || 1) * (+currentEl?.room_quantity || 0) + (el?.ADT || 0);
			el["CHD"] = (currentEl?.CHD || 0) * (+currentEl?.room_quantity || 0) + (el?.CHD || 0);
			// console.log(el, "elel");
			return el;
		}, {});
	}, [roomsValues]);
	return allRoomAndTraversFieldsValue;
};

export default useCalcAllRooms;
