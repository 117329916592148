import { useMemo } from "react";

type roomsValue = { ADT: number; CHD: number; room_quantity: number }[];
export const useIsEqualRoomGroups = (roomsValue: roomsValue) => {
	const isEqualRoomGroups = useMemo(() => {
		type storageType = {
			[key: string]: any;
		};
		const storage: storageType = {};
		const duplicates = [];
		for (let i = 0; i < roomsValue?.length; i++) {
			const { room_quantity, ADT, CHD } = roomsValue[i];
			const key = `${ADT}-${CHD}`;
			if (storage[key] !== undefined) {
				if (duplicates.length === 0) {
					duplicates.push(storage[key] + 1);
				}
				duplicates.push(i + 1);
			}
			storage[key] = i;
		}
		return duplicates.length > 0 ? duplicates : false;
	}, [roomsValue]);


  return isEqualRoomGroups;
};
