import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Modal } from 'antd';

const customPaddingLeftAndRight = css`
	padding: 0 0px !important;
`;
export const PaxInfoModalStyle = styled(Modal)`
	.RemoveBorderBottom {
		border-bottom: none !important;
	}
	width: 688px !important;
	border-radius: 11.5px !important;
	border: 1px solid ${({ theme }) => theme.token.colorPrimary} !important;
	overflow: hidden;
	padding-bottom: 0;
	.RoomAndTravelModal {
		.ant-space-item {
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.ant-modal-content {
			padding: 24px 0;
		}

		&__RoomGroupTitle {
			font-weight: 600;
			// @include customPaddingLeftAndRight();
		}

		&__Fields {
			max-height: 631px;
			${customPaddingLeftAndRight}
			overflow: auto;
			overflow-x: hidden;
		}

		&__Field {
			/* padding: 20px 0 !important; */
      padding-bottom: 20px;
			border-bottom: 1px solid $lightt-grey;
		}

		&__TotalInfo {
			margin-top: 10px;
			&__Label {
				color: $grey;
			}

			&__Text {
				color: black;
				font-weight: 500;
			}
		}

		&__ChildWarning {
			margin-top: 10px;
			&__Title {
				font-size: 16px;
			}
			&__Warning {
				color: #1492e6;
				font-size: 12px;
			}
		}
		&__Actions {
			${customPaddingLeftAndRight}
			margin-top: 24px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			.anticon-plus-circle {
				font-size: 20px;
			}

			&__AddBtn {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				gap: 10px;
				cursor: pointer;
				color: ${({ theme }) => theme.token.colorPrimary};

				&:hover {
					border: none !important;
				}

				&--disabled {
					cursor: not-allowed;
					color: gray;
				}
			}

			&__SaveBtn {
				width: auto !important;
				height: auto !important;
			}
		}

		&__RemoveBtn {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;

			&__Label {
				font-weight: bold;
				text-transform: capitalize;
			}
		}
		&__InputWithLabel {
			.ant-input-number .ant-input-number-input {
				// padding-right: 65px;
			}
		}

		&__B2cRoom {
			height: 100%;
			border: 1px solid #d9d9d9;
			border-radius: 8px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 0 12px;
			p {
				font-weight: 400;
				font-size: 16px;
			}
			div {
				// delete icon
				display: inline-flex;
				cursor: pointer;
			}
		}
		&__Input {
			position: relative;

			&__Icon {
				/* span { */
				font-size: 26px;
				color: ${({ theme }) => theme.token.colorPrimary};
				/* } */
				border: none;
				padding: 0;
				&:hover {
					color: inherit;
					border-color: transparent;
				}

				&:disabled {
					background-color: transparent !important;
          color: inherit;
          border: none;
          opacity: .3;
				}

        &:focus-visible {
          outline: none;
        }
			}

			.ant-input-suffix {
				gap: 10px;
			}
			.ant-input-number .ant-input-number-handler-down {
				border-block-start: none;
				left: 5%;
				top: 50%;
				transform: translateY(-50%);
				z-index: 10;
			}
			.ant-input-number .ant-input-number-handler-up {
				border-block-start: none;
				right: 5%;
				top: 50%;
				transform: translateY(-50%);
				z-index: 10;
			}
			.ant-input-number .ant-input-number-input {
				text-align: center;
			}
			.ant-input-number-handler-wrap .ant-input-number-handler {
				height: 100% !important;
				border-inline-start: none;
			}
			.ant-input-number-handler {
				position: absolute;
			}
			.ant-input-number-handler-wrap {
				display: block !important;
				opacity: 1 !important;
				position: static;
				z-index: 2;
			}
			.ant-input-number-handler-up-disabled,
			.ant-input-number-handler-down-disabled {
				opacity: 0.3;
			}

			&__Suffix {
				top: 2%;
				// background: white;
				pointer-events: none;
				height: 96%;
				left: 45%;
				display: flex;
				color: #acb0b5;
				justify-content: center;
				// width: 47%;
				z-index: 1;
				align-items: center;
				position: absolute;
			}
		}
	}
	.RoomAndTravelModal__Field__Childs {
		margin-top: 10px;
		// width: 100%;
	}
`;
