import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import useApi from "../../../config/network/useApi";
import { queryKeys } from "@config/react-query/queryKeys";
import { useQueryClient } from "react-query";
import { message } from "antd";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";

export const useGetHotelsList = ({ body, enabled }: { body: any; enabled: boolean }) => {
	const api = useApi("GetawayAPI");
	const { i18n } = useTranslation("website");
	const http = async () => {
		const res = await api.post(`/Umrah/HotelSearch`, body);
		return res;
	};
	return useQuery([queryKeys.umrah.getUmrahHotelList, { ...body, lang: i18n.language }], http, {
		select: (res) => {
			return res?.responseData;
		},
		enabled: enabled
	});
};

export const useGetHotelDropdown = ({ searchKey, cityCode }: { searchKey: string; cityCode: "SA1" | "SA4" | null }, options?: any) => {
	const api = useApi("UmrahAPI");
	const body = { pageIndex: 0, pageSize: 20, searchKey: searchKey, CityCode: cityCode ? cityCode : null };
	const http = async () => {
		const res = await api.post(`/UmraHotels/HotelDropDown`, body);
		return res;
	};

	return useQuery([queryKeys.umrah.getUmrahHotelDropdown, body], http, {
		select: (res) => {
			return res?.responseData;
		},

		...options
	});
};

type Post = {};
export const useGetSingleRoom = (body: any) => {
	const router = useRouter();
	const { i18n } = useTranslation("website");
	const api = useApi("GetawayAPI");
	const http = async () => {
		const res = await api.post(`/Umrah/HotelDetails`, body);
		return res;
	};
	return useQuery([queryKeys.umrah.getUmrahSingleRoom, { ...body, lang: i18n.language }], http, {
		select: (res) => {
			return res?.responseData?.response;
		},
		onSuccess: () => {
			const root = document.querySelector("#__next");
			root?.scrollIntoView({
				behavior: "smooth"
			});
		},
		enabled: body?.rooms?.length > 0 && !!body && router.isReady
	});
};

export const useCheckoutHotel = () => {
	const api = useApi("UmrahAPI");
	return useMutation(
		({ body }: { body: any }) => {
			return api.post(`/UmraHotels/Reservation`, { ...body });
		},
		{
			onError: (error: any) => {
				message.error(error?.message);
			}
		}
	);
};
